import axios from 'axios'

export const RESET_NEW_PASSWORD = async (password, token) => {
  const result = await axios.put(
    `${process.env.REACT_APP_BASE_URL}/api/users/resetpassword/${token}`,
    {
      password
    }
  )
  return result
}
