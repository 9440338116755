/* eslint-disable no-undef */
import { toast } from 'react-toastify'
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck'

function checkConnectionStatus(actionType) {
  // Browser compability check //
  // Check if 'navigator' object exists
  if (window.navigator) {
    // Check if 'onLine' property exists in 'navigator' object
    if ('onLine' in window.navigator) {
      const isOnline = window.navigator.onLine

      // Check if 'connection' object exists in 'navigator' object
      if ('connection' in window.navigator) {
        const currentDownlinkSpeed = window.navigator.connection.downlink

        let connectionQuality = 'optimal'

        if (currentDownlinkSpeed < 0.05) {
          connectionQuality = 'shumë e ngadaltë (slow-2g)'
        } else if (currentDownlinkSpeed < 0.2) {
          connectionQuality = 'e ngadaltë (2g)'
        } else if (currentDownlinkSpeed < 0.7) {
          connectionQuality = 'mesatare (3g)'
        } else {
          connectionQuality = 'optimal'
        }

        const content = (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* Assuming you have the 'NetworkCheckIcon' component defined */}
            <NetworkCheckIcon
              style={{ marginRight: '0.5rem', color: 'orange' }}
              fontSize="large"
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>Shpejtësia internetit tuaj është {connectionQuality}.</span>
              <span>{actionType} i shpalljes mund të marrë më shumë kohë!</span>
            </div>
          </div>
        )

        if (connectionQuality !== 'optimal' && isOnline) {
          // Assuming you have a 'toast' function defined for displaying messages
          toast(content)
        }
      } else {
        // Handle the case where 'connection' object is not supported
        console.log("'connection' object is not supported.")
        return
      }
    } else {
      // Handle the case where 'onLine' property is not supported
      console.log("'onLine' property is not supported.")
      return
    }
  } else {
    // Handle the case where 'navigator' object is not supported
    console.log("'navigator' object is not supported.")
    return
  }
}

export default checkConnectionStatus
