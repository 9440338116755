import React from 'react'
import { ProtectedRouteAdmin, ProtectedRouteBusiness } from './protectedRoutes'

const PostModifyContainer = React.lazy(
  async () => await import('pages/business/product/modify/postModifyContainer')
)
const ResetPasswordEmailProviderContainer = React.lazy(
  async () =>
    await import(
      'components/resetPassword/emailProvider/resetPasswordEmailProviderContainer'
    )
)
const ProvideNewPasswordContainer = React.lazy(
  async () =>
    await import(
      'components/resetPassword/provideNewPassword/provideNewPasswordContainer'
    )
)
const Partnership = React.lazy(
  async () => await import('pages/marketplace/partnership')
)
const PostsContainer = React.lazy(
  async () => await import('pages/business/product/list/postsContainer')
)
const RegistrationStepperContainer = React.lazy(
  async () =>
    await import(
      'pages/business/product/registration/registrationStepperContainer'
    )
)
const AccountSettingsView = React.lazy(
  async () => await import('pages/business/account')
)
const Marketplace = React.lazy(async () => await import('pages/marketplace'))
const Results = React.lazy(
  async () => await import('pages/marketplace/results')
)
const ProductContainer = React.lazy(
  async () => await import('pages/marketplace/product/productContainer')
)
const HomePageContent = React.lazy(
  async () => await import('pages/marketplace/home')
)
const PageNotFound404 = React.lazy(async () => await import('components/404'))

export const routeConfigs = [
  {
    path: '/',
    element: <Marketplace />,
    children: [
      { path: '', element: <HomePageContent /> },
      { path: 'results', element: <Results /> },
      { path: 'product/:postID/:productTitle', element: <ProductContainer /> },
      { path: 'reset', element: <ResetPasswordEmailProviderContainer /> },
      {
        path: 'reset/:resetPasswordToken',
        element: <ProvideNewPasswordContainer />
      },
      { path: 'partnership', element: <Partnership /> }
    ]
  },
  {
    path: '/business/:businessID',
    element: <ProtectedRouteBusiness />,
    children: [
      { path: '', element: <PostsContainer /> },
      { path: 'registration', element: <RegistrationStepperContainer /> },
      { path: 'account', element: <AccountSettingsView /> },
      { path: 'modify/:postID', element: <PostModifyContainer /> }
    ]
  },
  { path: '/admin', element: <ProtectedRouteAdmin /> },
  { path: '*', element: <PageNotFound404 /> }
]
