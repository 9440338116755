import axios from 'axios'

export const DELETE_CAR = ({ token, carID }) =>
  axios.delete(
    `${process.env.REACT_APP_BASE_URL}/api/cars/deleteCar/${carID}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
