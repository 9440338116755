import { useEffect, lazy } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { loginStateSelector } from 'redux/selectors/loginSelectors'

const Business = lazy(async () => await import('pages/business'))
const Admin = lazy(async () => await import('pages/administration'))

export function ProtectedRouteAdmin() {
  const navigate = useNavigate()
  const { userIsLogedIn, userIsAdmin, token } = useSelector(loginStateSelector)

  useEffect(() => {
    if (!userIsLogedIn || !userIsAdmin || !token) navigate('/')
  }, [userIsLogedIn, userIsAdmin])

  return !userIsLogedIn || !userIsAdmin ? null : <Admin />
}

export function ProtectedRouteBusiness() {
  const navigate = useNavigate()
  const { userIsLogedIn, token } = useSelector(loginStateSelector)

  useEffect(() => {
    if (!userIsLogedIn || !token) navigate('/')
  }, [userIsLogedIn])

  return !userIsLogedIn ? null : <Business />
}
