import { call, put, takeLatest } from 'redux-saga/effects'
import { setLoadingAction } from 'redux/actions/globalActions'
import {
  GET_SEARCH_ENGINE_RESULTS_TYPE,
  saveSearchEngineResults
} from 'redux/actions/searchEngineActions'
import { GET_SEARCH_ENGINE_RESULTS } from 'services/getSearchEngineResults'

function* getSearchEngineResultsSaga({ payload }) {
  try {
    yield put(setLoadingAction(true))
    const { data } = yield call(GET_SEARCH_ENGINE_RESULTS, payload)
    yield put(saveSearchEngineResults(data))
    yield put(setLoadingAction(false))
  } catch (error) {
    yield put(setLoadingAction(false))
  }
}

function* searchEngineWatcher() {
  yield takeLatest(GET_SEARCH_ENGINE_RESULTS_TYPE, getSearchEngineResultsSaga)
}

export default searchEngineWatcher
