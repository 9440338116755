import { toast } from 'react-toastify'
import { call, delay, put, takeLatest } from 'redux-saga/effects'
import {
  GET_COMPANIES,
  GET_COMPANY_TYPE,
  REGISTER_COMPANY_ACTION,
  UPDATE_COMPANY_ACTION,
  getCompaniesSuccess,
  saveCompanyAction,
  setLoadingAction
} from 'redux/actions/globalActions'
import { GET_ALL_COMPANIES } from 'services/getAllCompanies'
import { GET_COMPANY } from 'services/getCompany'
import { REGISTER_COMPANY } from 'services/registerCompany'
import { UPDATE_COMPANY } from 'services/updateCompany'

function* getCompanySaga({ payload }) {
  try {
    yield put(setLoadingAction(true))
    const { data } = yield call(GET_COMPANY, payload)
    yield put(saveCompanyAction(data))
    yield put(setLoadingAction(false))
  } catch (error) {
    yield put(setLoadingAction(false))
  }
}

function* getCompaniesSaga() {
  try {
    yield put(setLoadingAction(true))
    const { data } = yield call(GET_ALL_COMPANIES)
    yield put(getCompaniesSuccess(data))
    yield put(setLoadingAction(false))
  } catch (error) {
    yield put(setLoadingAction(false))
    toast.error(
      error?.response?.data?.message || 'An unexpected error occurred'
    )
  }
}

function* updateCompanySaga({ payload }) {
  const { refreshCompaniesList } = payload
  try {
    yield put(setLoadingAction(true))
    yield call(UPDATE_COMPANY, payload)
    if (refreshCompaniesList) {
      yield delay(1000)
      yield call(getCompaniesSaga)
    }
    yield put(setLoadingAction(false))
    toast.success('Company settings updated.')
  } catch (error) {
    yield put(setLoadingAction(false))
    toast.error(
      error?.response?.data?.message || 'An unexpected error occurred'
    )
  }
}

function* registerCompanySaga({ payload }) {
  try {
    yield put(setLoadingAction(true))
    yield call(REGISTER_COMPANY, payload)
    yield put(setLoadingAction(false))
    toast.success('Company registered.')
  } catch (error) {
    yield put(setLoadingAction(false))
    toast.error(
      error?.response?.data?.message || 'An unexpected error occurred'
    )
  }
}

function* companyWatcher() {
  yield takeLatest(GET_COMPANY_TYPE, getCompanySaga)
  yield takeLatest(GET_COMPANIES, getCompaniesSaga)
  yield takeLatest(UPDATE_COMPANY_ACTION, updateCompanySaga)
  yield takeLatest(REGISTER_COMPANY_ACTION, registerCompanySaga)
}

export default companyWatcher
