/* eslint-disable no-undef */
import axios from 'axios'

export const CREATE_NEW_CAR = ({ token, selectedCarBrand, selectedCarModel }) =>
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/cars/createNewCar`,
    { brand: selectedCarBrand, model: selectedCarModel },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
