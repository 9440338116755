import axios from 'axios'

export const UPDATE_PRODUCT = ({ state, token, postID }) => {
  const {
    title,
    description,
    price,
    photos,
    carBrand,
    carModel,
    carYear,
    condition,
    category,
    partIsUniversal,
    priceIsDefined
  } = state

  const formData = new FormData()
  formData.append('title', title)
  formData.append('partIsUniversal', partIsUniversal)
  formData.append('brand', carBrand)
  formData.append('model', carModel)
  formData.append('condition', condition)
  formData.append('description', description)
  formData.append('priceIsDefined', priceIsDefined)
  formData.append('price', price)
  formData.append('category', category)
  for (let i = 0; i < carYear?.length; i++) {
    formData.append('generation', carYear[i])
  }
  for (let i = 0; i < photos?.length; i++) {
    formData.append('image', photos[i])
  }

  return axios.patch(
    `${process.env.REACT_APP_BASE_URL}/api/products/updateProduct/${postID}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    }
  )
}
