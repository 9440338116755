import { LOG_IN_SUCCESS, LOG_OUT_TYPE } from 'redux/actions/loginActions'

const initialState = {
  userIsLogedIn: false,
  userIsAdmin: false,
  token: null,
  user: {}
}

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOG_IN_SUCCESS: {
      const { token, user } = action.payload
      const userIsAdmin = user.admin

      return {
        ...state,
        userIsAdmin: userIsAdmin ? true : false,
        userIsLogedIn: true,
        token: token,
        user: user
      }
    }

    case LOG_OUT_TYPE: {
      return {
        ...state,
        userIsLogedIn: false,
        userIsAdmin: null,
        user: {},
        token: null
      }
    }

    default:
      return state
  }
}

export default loginReducer
