import axios from 'axios'

export const GET_SEARCH_ENGINE_RESULTS = ({
  title,
  brand,
  model,
  generation,
  category,
  city,
  pageIndex
}) =>
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/products/getSearchEngineResults`,
    {
      title,
      brand,
      model,
      generation,
      category,
      city,
      pageIndex
    }
  )
