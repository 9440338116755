import React, { useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { deleteExpiredData } from 'utils/clearLocalStorage'
import { loginStateSelector } from 'redux/selectors/loginSelectors'
import { useSelector } from 'react-redux'
import { routeConfigs } from 'routes/routes'

function App() {
  const navigate = useNavigate()
  const { user, userIsLogedIn, userIsAdmin } = useSelector(loginStateSelector)

  // Listen for login and redirect acordingly
  useEffect(() => {
    if (userIsLogedIn && userIsAdmin) {
      return navigate('/admin')
    }
    if (userIsLogedIn && !userIsAdmin) {
      return navigate(`/business/${user.id}`)
    }
  }, [userIsLogedIn, userIsAdmin])

  // Handle Expiration Date for counting post views
  useEffect(() => {
    setInterval(deleteExpiredData, 86400000)
  }, [])

  return (
    <Routes>
      {routeConfigs?.map((routeConfig, index) => (
        <Route
          key={index}
          path={routeConfig.path}
          element={routeConfig.element}>
          {routeConfig.children &&
            routeConfig.children.map((childRoute, childIndex) => (
              <Route
                key={childIndex}
                path={childRoute.path}
                element={childRoute.element}
              />
            ))}
        </Route>
      ))}
    </Routes>
  )
}

export default App
