import { GET_COMPANY_PRODUCTS_SUCCESS } from 'redux/actions/globalActions'
import {
  GET_SINGLE_PRODUCT_SUCCESS,
  RESET_FORM_DATA,
  SAVE_MOST_RECENT_POSTS_TYPE,
  SAVE_PRODUCT_INPUT,
  SAVE_SINGLE_POST_WITH_OWNER_TYPE,
  SAVE_UPDATE_PRODUCT_INPUT
} from 'redux/actions/productAction'

const initialState = {
  productToUpdate: {},
  formData: {},
  recentPosts: [],
  productData: {},
  companyProducts: []
}

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PRODUCT_INPUT: {
      const { fieldName, fieldValue } = action.payload
      return {
        ...state,
        formData: {
          ...state?.formData,
          [fieldName]: fieldValue
        }
      }
    }
    case SAVE_UPDATE_PRODUCT_INPUT: {
      const { fieldName, fieldValue } = action.payload
      return {
        ...state,
        productToUpdate: {
          ...state.productToUpdate,
          [fieldName]: fieldValue
        }
      }
    }

    case RESET_FORM_DATA: {
      return {
        ...state,
        formData: {}
      }
    }

    case GET_SINGLE_PRODUCT_SUCCESS: {
      return {
        ...state,
        productToUpdate: action.payload
      }
    }

    case SAVE_MOST_RECENT_POSTS_TYPE: {
      return {
        ...state,
        recentPosts: action.payload
      }
    }

    case SAVE_SINGLE_POST_WITH_OWNER_TYPE: {
      return {
        ...state,
        productData: action.payload
      }
    }

    case GET_COMPANY_PRODUCTS_SUCCESS: {
      return {
        ...state,
        companyProducts: action.payload
      }
    }

    default:
      return state
  }
}

export default productReducer
