import axios from 'axios'

export const UPDATE_ACCOUNT_PASSWORD = ({ data, token, businessID }) => {
  const { oldPassword, newPassword } = data

  const formData = {
    id: businessID,
    oldPassword,
    newPassword
  }

  return axios.patch(
    `${process.env.REACT_APP_BASE_URL}/api/users/changepassword`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
}
