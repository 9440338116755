import createSagaMiddleware from 'redux-saga'
import storage from 'redux-persist/lib/storage'
import rootSaga from './saga/root'
import { persistStore, persistReducer } from 'redux-persist'
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import searchEngineReducer from './reducers/searchEngineReducer'
import loginReducer from './reducers/loginReducer'
import globalReducer from './reducers/globalReducer'
import productReducer from './reducers/productReducer'

const rootReducer = combineReducers({
  loginState: loginReducer,
  globalState: globalReducer,
  productState: productReducer,
  searchEngineState: searchEngineReducer
})

const persistConfig = {
  key: 'root',
  storage,
  blacklist: []
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// Create Saga Middleware
const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    }).concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== 'production'
})

// Run Sagas
sagaMiddleware.run(rootSaga)

// Create and export persistor
const persistor = persistStore(store)

// Export the store and persistor
export { store, persistor }
