import {
  CLEAR_SEARCH_INPUTS_TYPE,
  OPEN_CRITERES_MENU_TYPE,
  SAVE_SEARCH_ENGINE_INPUTS_TYPE,
  SAVE_SEARCH_ENGINE_RESULTS,
  SET_PAGE_INDEX_TYPE
} from 'redux/actions/searchEngineActions'

// Initial state
const initialState = {
  searchResults: [],
  openMoreCriteresMenu: false,
  searchValues: {
    title: '',
    city: '',
    category: '',
    brand: '',
    model: '',
    generation: '',
    pageIndex: 1
  }
}

// Reducer function
const searchEngineReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SEARCH_ENGINE_INPUTS_TYPE:
      return {
        ...state,
        searchValues: action.payload
      }
    case CLEAR_SEARCH_INPUTS_TYPE:
      return {
        ...state,
        searchValues: {
          title: '',
          city: '',
          category: '',
          brand: '',
          model: '',
          generation: '',
          pageIndex: 1
        }
      }
    case SET_PAGE_INDEX_TYPE:
      return {
        ...state,
        searchValues: {
          ...state.searchValues,
          pageIndex: action.payload
        }
      }
    case OPEN_CRITERES_MENU_TYPE:
      return {
        ...state,
        openMoreCriteresMenu: !state.openMoreCriteresMenu
      }
    case SAVE_SEARCH_ENGINE_RESULTS:
      return {
        ...state,
        searchResults: action.payload
      }
    default:
      return state
  }
}

export default searchEngineReducer
