export const GET_SEARCH_ENGINE_RESULTS_TYPE =
  'searchEngine/GET_SEARCH_ENGINE_RESULTS_TYPE'
export const getSearchEngineResultsAction = (payload) => ({
  type: GET_SEARCH_ENGINE_RESULTS_TYPE,
  payload
})

export const SAVE_SEARCH_ENGINE_RESULTS =
  'searchEngine/SAVE_SEARCH_ENGINE_RESULTS'
export const saveSearchEngineResults = (payload) => ({
  type: SAVE_SEARCH_ENGINE_RESULTS,
  payload
})

export const SAVE_SEARCH_ENGINE_INPUTS_TYPE =
  'searchEngine/SAVE_SEARCH_ENGINE_INPUTS_TYPE'
export const saveSearchEngineInputsAction = (payload) => ({
  type: SAVE_SEARCH_ENGINE_INPUTS_TYPE,
  payload
})

export const CLEAR_SEARCH_INPUTS_TYPE = 'searchEngine/CLEAR_SEARCH_INPUTS_TYPE'
export const clearSearchEngineInputsAction = () => ({
  type: CLEAR_SEARCH_INPUTS_TYPE
})

export const SET_PAGE_INDEX_TYPE = 'searchEngine/SET_PAGE_INDEX_TYPE'
export const setPageIndexAction = (payload) => ({
  type: SET_PAGE_INDEX_TYPE,
  payload
})

export const OPEN_CRITERES_MENU_TYPE = 'searchEngine/OPEN_CRITERES_MENU_TYPE'
export const openCritersMenuAction = () => ({
  type: OPEN_CRITERES_MENU_TYPE
})
