import axios from 'axios'

export const SENT_REST_PASSWORD_EMAIL = async (email) => {
  const result = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/users/forgotpassword`,
    {
      email
    }
  )
  return result
}
