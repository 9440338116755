import { toast } from 'react-toastify'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  CREATE_NEW_VEHICLE_ACTION,
  DELETE_VEHICLE_ACTION,
  GET_CITIES,
  GET_PRODUCT_CATEGORIES,
  GET_VEHICLES_CATALOG,
  SAVE_NEW_PASSWORD,
  SEND_RESET_PASSWORD_EMAIL,
  UPDATE_ACCOUNT_PASSWORD_ACTIONTYPE,
  getCitiesSuccess,
  getProductCategoriesSuccess,
  getVehiclesCatalogSuccess,
  setLoadingAction
} from 'redux/actions/globalActions'
import { CREATE_NEW_CAR } from 'services/createNewCar'
import { DELETE_CAR } from 'services/deleteCar'
import { SENT_REST_PASSWORD_EMAIL } from 'services/forgotPassword'
import { GET_ALL_CARS } from 'services/getAllCars'
import { GET_ALL_CATEGORIES } from 'services/getAllCategories'
import { GET_ALL_CITIES } from 'services/getCities'
import { UPDATE_ACCOUNT_PASSWORD } from 'services/passwordReset'
import { RESET_NEW_PASSWORD } from 'services/resetNewPasword'

function* getVehiclesCatalog() {
  try {
    yield put(setLoadingAction(true))
    const { data } = yield call(GET_ALL_CARS)
    yield put(getVehiclesCatalogSuccess(data))
    yield put(setLoadingAction(false))
  } catch (error) {
    yield put(setLoadingAction(false))
    toast.error(
      error?.response?.data?.message || 'An unexpected error occurred'
    )
  }
}

function* createNewVehicleSaga({ payload }) {
  try {
    yield put(setLoadingAction(true))
    yield call(CREATE_NEW_CAR, payload)
    yield call(getVehiclesCatalog)
    yield put(setLoadingAction(false))
    toast.success('Car registered.')
  } catch (error) {
    yield put(setLoadingAction(false))
    toast.error(
      error?.response?.data?.message || 'An unexpected error occurred'
    )
  }
}

function* deleteVehicleSaga({ payload }) {
  try {
    yield put(setLoadingAction(true))
    yield call(DELETE_CAR, payload)
    yield call(getVehiclesCatalog)
    yield put(setLoadingAction(false))
    toast.success('Car deleted.')
  } catch (error) {
    yield put(setLoadingAction(false))
    toast.error(
      error?.response?.data?.message || 'An unexpected error occurred'
    )
  }
}

function* getProductCategories() {
  try {
    const { data } = yield call(GET_ALL_CATEGORIES)
    yield put(getProductCategoriesSuccess(data))
  } catch (error) {
    toast.error(
      error?.response?.data?.message || 'An unexpected error occurred'
    )
  }
}

function* getCitiesSaga() {
  try {
    yield put(setLoadingAction(true))
    const { data } = yield call(GET_ALL_CITIES)
    yield put(getCitiesSuccess(data))
    yield put(setLoadingAction(false))
  } catch (error) {
    yield put(setLoadingAction(false))
    toast.error(
      error?.response?.data?.message || 'An unexpected error occurred'
    )
  }
}

function* resetPasswordSaga({ payload }) {
  try {
    yield put(setLoadingAction(true))
    yield call(SENT_REST_PASSWORD_EMAIL, payload)
    yield put(setLoadingAction(false))
    toast.success(
      `Kerkesa per te ndryshuar fjalekalimin tuaj eshte derguar me sukses!`
    )
  } catch (error) {
    yield put(setLoadingAction(false))
    const errorMessage = error.response.data.message
    const userDontExist = 'User does not exist'
    toast.error(
      errorMessage === userDontExist
        ? 'Email adresa nuk ekziston'
        : 'Pati nje problem, ju lutem riprovoni me vone'
    )
  }
}

function* saveNewPasswordSaga({ payload }) {
  try {
    yield put(setLoadingAction(true))
    yield call(RESET_NEW_PASSWORD, payload)
    yield put(setLoadingAction(false))
    toast.success('Fjalekalimi u ndryshua me sukses!')
  } catch (error) {
    yield put(setLoadingAction(false))
    const messageError = error.response.data.message
    const expiredMessage = 'Invalid or expired token'

    toast.error(
      messageError === expiredMessage
        ? 'Afati per nderrimin e fjalekalimit ka perfunduar!'
        : 'Ups, pati nje problem, ju lutem provoni me vone!'
    )
  }
}

function* updateAccountPasswordSaga({ payload }) {
  try {
    yield put(setLoadingAction(true))
    yield call(UPDATE_ACCOUNT_PASSWORD, payload)
    yield put(setLoadingAction(false))
    toast.success('Fjalekalimi u ndryshua me sukses!')
  } catch (error) {
    yield put(setLoadingAction(false))
    toast.error('Fjalekalimi i vjeter eshte gabim!')
  }
}

function* globalWatcher() {
  yield takeLatest(GET_VEHICLES_CATALOG, getVehiclesCatalog)
  yield takeLatest(GET_PRODUCT_CATEGORIES, getProductCategories)
  yield takeLatest(GET_CITIES, getCitiesSaga)
  yield takeLatest(CREATE_NEW_VEHICLE_ACTION, createNewVehicleSaga)
  yield takeLatest(DELETE_VEHICLE_ACTION, deleteVehicleSaga)
  yield takeLatest(SEND_RESET_PASSWORD_EMAIL, resetPasswordSaga)
  yield takeLatest(SAVE_NEW_PASSWORD, saveNewPasswordSaga)
  yield takeLatest(
    UPDATE_ACCOUNT_PASSWORD_ACTIONTYPE,
    updateAccountPasswordSaga
  )
}

export default globalWatcher
