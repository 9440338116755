export const LOG_IN_TYPE = 'login/LOG_IN_TYPE'
export const logInAction = (payload) => ({
  type: LOG_IN_TYPE,
  payload
})

export const LOG_IN_SUCCESS = 'login/LOG_IN_SUCCESS'
export const logInSuccesAction = (payload) => ({
  type: LOG_IN_SUCCESS,
  payload
})

export const LOG_OUT_TYPE = 'login/LOG_OUT_TYPE'
export const logOutAction = () => ({
  type: LOG_OUT_TYPE
})
