export const SET_LOADING = 'global/SET_LOADING'
export const setLoadingAction = (payload) => ({
  type: SET_LOADING,
  payload
})

export const SET_STEPPER_NEXT_STEP = 'global/SET_STEPPER_NEXT_STEP'
export const setStepperNextStepAction = () => ({
  type: SET_STEPPER_NEXT_STEP
})

export const SET_STEPPER_PREVIOUS_STEP = 'global/SET_STEPPER_PREVIOUS_STEP'
export const setStepperPreviousStepAction = () => ({
  type: SET_STEPPER_PREVIOUS_STEP
})

export const RESET_STEPPER = 'global/RESET_STEPPER'
export const resetStepperAction = () => ({
  type: RESET_STEPPER
})

export const GET_VEHICLES_CATALOG = 'global/GET_VEHICLES_CATALOG'
export const getVehiclesCatalog = () => ({
  type: GET_VEHICLES_CATALOG
})

export const GET_VEHICLES_CATALOG_SUCCESS =
  'global/GET_VEHICLES_CATALOG_SUCCESS'
export const getVehiclesCatalogSuccess = (payload) => ({
  type: GET_VEHICLES_CATALOG_SUCCESS,
  payload
})

export const GET_PRODUCT_CATEGORIES = 'global/GET_PRODUCT_CATEGORIES'
export const getProductCategories = () => ({
  type: GET_PRODUCT_CATEGORIES
})

export const GET_PRODUCT_CATEGORIES_SUCCESS =
  'global/GET_PRODUCT_CATEGORIES_SUCCESS'
export const getProductCategoriesSuccess = (payload) => ({
  type: GET_PRODUCT_CATEGORIES_SUCCESS,
  payload
})

export const GET_COMPANIES = 'global/GET_COMPANIES'
export const getCompaniesAction = () => ({
  type: GET_COMPANIES
})

export const GET_COMPANY_TYPE = 'global/GET_COMPANY_TYPE'
export const getCompanyAction = (payload) => ({
  type: GET_COMPANY_TYPE,
  payload
})

export const SAVE_COMPANY_TYPE = 'global/SAVE_COMPANY_TYPE'
export const saveCompanyAction = (payload) => ({
  type: SAVE_COMPANY_TYPE,
  payload
})

export const GET_COMPANIES_SUCCESS = 'global/GET_COMPANIES_SUCCESS'
export const getCompaniesSuccess = (payload) => ({
  type: GET_COMPANIES_SUCCESS,
  payload
})

export const GET_COMPANY_PRODUCTS = 'global/GET_COMPANY_PRODUCTS'
export const getCompanyProducts = (payload) => ({
  type: GET_COMPANY_PRODUCTS,
  payload
})

export const GET_COMPANY_PRODUCTS_SUCCESS =
  'global/GET_COMPANY_PRODUCTS_SUCCESS'
export const getCompanyProductsSuccess = (payload) => ({
  type: GET_COMPANY_PRODUCTS_SUCCESS,
  payload
})

export const GET_CITIES = 'global/GET_CITIES'
export const getCitiesAction = (payload) => ({
  type: GET_CITIES,
  payload
})

export const CREATE_NEW_VEHICLE_ACTION = 'global/CREATE_NEW_VEHICLE_ACTION'
export const createNewVehicleAction = (payload) => ({
  type: CREATE_NEW_VEHICLE_ACTION,
  payload
})

export const DELETE_VEHICLE_ACTION = 'global/DELETE_VEHICLE_ACTION'
export const deleteVehicleAction = (payload) => ({
  type: DELETE_VEHICLE_ACTION,
  payload
})

export const UPDATE_COMPANY_ACTION = 'global/UPDATE_COMPANY_ACTION'
export const updateCompanyAction = (payload) => ({
  type: UPDATE_COMPANY_ACTION,
  payload
})

export const REGISTER_COMPANY_ACTION = 'global/REGISTER_COMPANY_ACTION'
export const registerCompanyAction = (payload) => ({
  type: REGISTER_COMPANY_ACTION,
  payload
})

export const GET_CITIES_SUCCESS = 'global/GET_CITIES_SUCCESS'
export const getCitiesSuccess = (payload) => ({
  type: GET_CITIES_SUCCESS,
  payload
})

export const SEND_RESET_PASSWORD_EMAIL = 'global/SEND_RESET_PASSWORD_EMAIL'
export const sendResetPasswordEmail = (payload) => ({
  type: SEND_RESET_PASSWORD_EMAIL,
  payload
})

export const SAVE_NEW_PASSWORD = 'global/SAVE_NEW_PASSWORD'
export const saveNewPasswordAction = (payload) => ({
  type: SAVE_NEW_PASSWORD,
  payload
})

export const UPDATE_ACCOUNT_PASSWORD_ACTIONTYPE =
  'global/UPDATE_ACCOUNT_PASSWORD_ACTIONTYPE'
export const updateAccountPasswordAction = (payload) => ({
  type: UPDATE_ACCOUNT_PASSWORD_ACTIONTYPE,
  payload
})
