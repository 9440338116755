import axios from 'axios'

export const UPDATE_COMPANY = ({ data, token, businessID }) => {
  const formData = {
    _id: businessID,
    phone: data?.contact?.phone,
    email: data?.contact?.contactMail,
    city: data?.address?.city,
    accountStatus: data?.accountStatus,
    shipping: data?.shipping,
    name: data?.name,
    bio: data?.bio
  }

  axios.patch(
    `${process.env.REACT_APP_BASE_URL}/api/users/updateuser`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
}
