import { spawn, all } from 'redux-saga/effects'
import productWatcher from './productSaga'
import loginWatcher from './loginSaga'
import globalWatcher from './globalSaga'
import searchEngineWatcher from './searchEngineSaga'
import companyWatcher from './companySaga'

export default function* rootSaga() {
  yield all([
    spawn(productWatcher),
    spawn(loginWatcher),
    spawn(companyWatcher),
    spawn(globalWatcher),
    spawn(searchEngineWatcher)
  ])
}
