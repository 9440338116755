import axios from 'axios'

export const CREATE_MULTIPLE_PRODUCTS = ({ products, token, userID }) =>
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/products/createMultipleProducts/${userID}`,
    { products },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
  )
