import {
  GET_CITIES_SUCCESS,
  GET_COMPANIES_SUCCESS,
  GET_PRODUCT_CATEGORIES_SUCCESS,
  GET_VEHICLES_CATALOG_SUCCESS,
  RESET_STEPPER,
  SAVE_COMPANY_TYPE,
  SET_LOADING,
  SET_STEPPER_NEXT_STEP,
  SET_STEPPER_PREVIOUS_STEP
} from 'redux/actions/globalActions'

const initialState = {
  loading: false,
  stepper: {
    activeStep: 0
  },
  vehiclesCatalog: [],
  productCategories: [],
  companies: [],
  cities: [],
  company: {}
}

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }

    case SET_STEPPER_NEXT_STEP: {
      return {
        ...state,
        stepper: {
          activeStep: state?.stepper?.activeStep + 1
        }
      }
    }

    case SET_STEPPER_PREVIOUS_STEP: {
      return {
        ...state,
        stepper: {
          activeStep: state?.stepper?.activeStep - 1
        }
      }
    }

    case RESET_STEPPER: {
      return {
        ...state,
        stepper: {
          activeStep: 0
        }
      }
    }

    case GET_VEHICLES_CATALOG_SUCCESS: {
      return {
        ...state,
        vehiclesCatalog: action.payload
      }
    }

    case GET_PRODUCT_CATEGORIES_SUCCESS: {
      return {
        ...state,
        productCategories: action.payload
      }
    }

    case GET_COMPANIES_SUCCESS: {
      return {
        ...state,
        companies: action.payload
      }
    }

    case GET_CITIES_SUCCESS: {
      return {
        ...state,
        cities: action.payload
      }
    }

    case SAVE_COMPANY_TYPE: {
      return {
        ...state,
        company: action.payload
      }
    }

    default:
      return state
  }
}

export default globalReducer
