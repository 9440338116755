import axios from 'axios'

export const CREATE_PRODUCT = ({ formData, photos, token, businessID }) => {
  const {
    title,
    description,
    price,
    brand,
    model,
    generation,
    condition,
    category,
    partIsUniversal,
    priceIsDefined
  } = formData

  const data = new FormData()
  data.append('title', title)
  data.append('partIsUniversal', partIsUniversal)
  data.append('brand', brand)
  data.append('model', model)
  data.append('condition', condition)
  data.append('description', description)
  data.append('priceIsDefined', priceIsDefined)
  data.append('price', price)
  data.append('category', category)
  for (let i = 0; i < generation?.length; i++) {
    data.append('generation', generation[i])
  }
  for (let i = 0; i < photos?.length; i++) {
    data.append('image', photos[i])
  }

  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/products/createProduct/${businessID}`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    }
  )
}
