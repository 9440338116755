// Handle Expiration for counting post views
export const deleteExpiredData = () => {
  const now = Date.now()
  // Loop through all keys in localStorage and delete the data if it has expired
  for (let i = 0; i < localStorage?.length; i++) {
    const key = localStorage.key(i)
    const data = JSON.parse(localStorage.getItem(key))
    if (
      data &&
      (data.expiration < now - 3600000 || data.expiration < now - 86400000)
    ) {
      localStorage.removeItem(key)
    }
  }
}
