import { toast } from 'react-toastify'
import { takeLatest, put, call } from 'redux-saga/effects'
import { setLoadingAction } from 'redux/actions/globalActions'
import { LOG_IN_TYPE, logInSuccesAction } from 'redux/actions/loginActions'
import { LOGIN } from 'services/login'

function* loginSaga({ payload }) {
  try {
    yield put(setLoadingAction(true))
    const { data } = yield call(LOGIN, payload)
    yield put(logInSuccesAction(data))
    yield put(setLoadingAction(false))
  } catch (error) {
    yield put(setLoadingAction(false))
    toast.error('Emaili ose fjalëkalimi i gabuar!')
  }
}

function* loginWatcher() {
  yield takeLatest(LOG_IN_TYPE, loginSaga)
}

export default loginWatcher
