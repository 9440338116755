/* eslint-disable no-console */
import { toast } from 'react-toastify'
import { takeLatest, call, put } from 'redux-saga/effects'
import {
  GET_COMPANY_PRODUCTS,
  getCompanyProductsSuccess,
  resetStepperAction,
  setLoadingAction
} from 'redux/actions/globalActions'
import {
  CREATE_PRODUCTS_ON_MASS_ACTION,
  CREATE_PRODUCT_ACTION_TYPE,
  DELETE_PRODUCT,
  GET_MOST_RECENT_POSTS_TYPE,
  GET_SINGLE_POST_WITH_OWNER_TYPE,
  GET_SINGLE_PRODUCT_ACTION,
  MODIFY_PRODUCT_ACTION,
  getSingleProductSuccess,
  resetFormDataAction,
  saveMostRecentPostsAction,
  saveSinglePostWithOwnerDataAction
} from 'redux/actions/productAction'
import { CREATE_MULTIPLE_PRODUCTS } from 'services/createMultipleProducts'
import { CREATE_PRODUCT } from 'services/createProduct'
import { DELETE_POST } from 'services/deletePost'
import { GET_COMPANY_POSTS } from 'services/getCompanyPosts'
import { GET_MOST_RECENT_POSTS } from 'services/getMostRecentPosts'
import { GET_SINGLE_PRODUCT } from 'services/getSingleProduct'
import { GET_SINGLE_POST_WITH_OWNER } from 'services/getSingleProductWithOwner'
import { UPDATE_PRODUCT } from 'services/upadteProduct'
import checkConnectionStatus from 'utils/ConnectionStatusChecker'

function* createProductSaga({ payload }) {
  try {
    yield put(setLoadingAction(true))
    checkConnectionStatus(payload.actionType)
    yield call(CREATE_PRODUCT, payload)
    yield put(resetStepperAction())
    yield put(resetFormDataAction())
    yield put(setLoadingAction(false))
    toast.success('Shpallja u krijua me sukses!')
  } catch (error) {
    yield put(setLoadingAction(false))
    if (error?.response?.status === 400) {
      toast.warning(error?.response?.data?.message)
    } else {
      toast.error('Ups pati nje problem, provoni perseri!')
    }
  }
}

function* getSingleProductSaga({ payload }) {
  try {
    const { data } = yield call(GET_SINGLE_PRODUCT, payload)
    yield put(getSingleProductSuccess(data))
  } catch (error) {
    toast.error(error?.response?.data?.message)
  }
}

function* modifyProductSaga({ payload }) {
  try {
    yield put(setLoadingAction(true))
    checkConnectionStatus(payload.actionType)
    yield call(UPDATE_PRODUCT, payload)
    yield put(setLoadingAction(false))
    toast.success('Shpallja u ruajt me sukses!')
  } catch (error) {
    yield put(setLoadingAction(false))
    if (error?.response?.status === 400) {
      toast.warning(error?.response?.data?.message)
    } else {
      toast.error('Ups pati nje problem, provoni perseri!')
    }
  }
}

function* getCompanyProductsSaga({ payload }) {
  try {
    yield put(setLoadingAction(true))
    const { data } = yield call(GET_COMPANY_POSTS, payload)
    yield put(getCompanyProductsSuccess(data))
    yield put(setLoadingAction(false))
  } catch (error) {
    yield put(setLoadingAction(false))
  }
}

function* createProductsOnMassSaga({ payload }) {
  try {
    yield put(setLoadingAction(true))
    yield call(CREATE_MULTIPLE_PRODUCTS, payload)
    yield put(setLoadingAction(false))
    toast.success('Products saved successfully.')
  } catch (error) {
    yield put(setLoadingAction(false))
    toast.error(error?.response?.data?.message)
  }
}

function* getMostRecentPostsSaga() {
  try {
    yield put(setLoadingAction(true))
    const { data } = yield call(GET_MOST_RECENT_POSTS)
    yield put(saveMostRecentPostsAction(data))
    yield put(setLoadingAction(false))
  } catch (error) {
    yield put(setLoadingAction(false))
  }
}

function* getSinglePostWithOwnerDataSaga({ payload }) {
  try {
    yield put(setLoadingAction(true))
    const { data } = yield call(GET_SINGLE_POST_WITH_OWNER, payload)
    yield put(saveSinglePostWithOwnerDataAction(data))
    yield put(setLoadingAction(false))
  } catch (error) {
    yield put(setLoadingAction(false))
  }
}

function* deleteProduct({ payload }) {
  try {
    yield put(setLoadingAction(true))
    yield call(DELETE_POST, payload)
    yield* getCompanyProductsSaga({ payload: payload?.businessID })
    yield put(setLoadingAction(false))
    toast.success('Shpallja u fshi me sukses!')
  } catch (error) {
    yield put(setLoadingAction(false))
    toast.error('Ups pati nje problem, provoni perseri!')
  }
}

function* productWatcher() {
  yield takeLatest(CREATE_PRODUCT_ACTION_TYPE, createProductSaga)
  yield takeLatest(GET_SINGLE_PRODUCT_ACTION, getSingleProductSaga)
  yield takeLatest(MODIFY_PRODUCT_ACTION, modifyProductSaga)
  yield takeLatest(CREATE_PRODUCTS_ON_MASS_ACTION, createProductsOnMassSaga)
  yield takeLatest(GET_MOST_RECENT_POSTS_TYPE, getMostRecentPostsSaga)
  yield takeLatest(
    GET_SINGLE_POST_WITH_OWNER_TYPE,
    getSinglePostWithOwnerDataSaga
  )
  yield takeLatest(DELETE_PRODUCT, deleteProduct)
  yield takeLatest(GET_COMPANY_PRODUCTS, getCompanyProductsSaga)
}

export default productWatcher
