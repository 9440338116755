export const CREATE_PRODUCT_ACTION_TYPE = 'product/CREATE_PRODUCT_ACTION_TYPE'
export const createProductAction = (payload) => ({
  type: CREATE_PRODUCT_ACTION_TYPE,
  payload
})

export const SAVE_PRODUCT_INPUT = 'product/SAVE_PRODUCT_INPUT'
export const saveProductInputAction = (payload) => ({
  type: SAVE_PRODUCT_INPUT,
  payload
})
export const SAVE_UPDATE_PRODUCT_INPUT = 'product/SAVE_UPDATE_PRODUCT_INPUT'
export const saveUpdateProductInputAction = (payload) => ({
  type: SAVE_UPDATE_PRODUCT_INPUT,
  payload
})

export const RESET_FORM_DATA = 'product/RESET_FORM_DATA'
export const resetFormDataAction = () => ({
  type: RESET_FORM_DATA
})

export const GET_SINGLE_PRODUCT_ACTION = 'product/GET_SINGLE_PRODUCT_ACTION'
export const getSingleProductAction = (payload) => ({
  type: GET_SINGLE_PRODUCT_ACTION,
  payload
})

export const GET_SINGLE_PRODUCT_SUCCESS = 'product/GET_SINGLE_PRODUCT_SUCCESS'
export const getSingleProductSuccess = (payload) => ({
  type: GET_SINGLE_PRODUCT_SUCCESS,
  payload
})

export const MODIFY_PRODUCT_ACTION = 'product/MODIFY_PRODUCT_ACTION'
export const modifyProductAction = (payload) => ({
  type: MODIFY_PRODUCT_ACTION,
  payload
})

export const CREATE_PRODUCTS_ON_MASS_ACTION =
  'product/CREATE_PRODUCTS_ON_MASS_ACTION'
export const createProductsOnMassAction = ({ products, token, userID }) => ({
  type: CREATE_PRODUCTS_ON_MASS_ACTION,
  payload: { products, token, userID }
})

export const GET_MOST_RECENT_POSTS_TYPE = 'product/GET_MOST_RECENT_POSTS_TYPE'
export const getMostRecentPostsAction = () => ({
  type: GET_MOST_RECENT_POSTS_TYPE
})

export const SAVE_MOST_RECENT_POSTS_TYPE = 'product/SAVE_MOST_RECENT_POSTS_TYPE'
export const saveMostRecentPostsAction = (payload) => ({
  type: SAVE_MOST_RECENT_POSTS_TYPE,
  payload
})

export const GET_SINGLE_POST_WITH_OWNER_TYPE =
  'product/GET_SINGLE_POST_WITH_OWNER_TYPE'
export const getSinglePostWithOwnerDataAction = (payload) => ({
  type: GET_SINGLE_POST_WITH_OWNER_TYPE,
  payload
})

export const DELETE_PRODUCT = 'product/DELETE_PRODUCT'
export const deleteProduct = (payload) => ({
  type: DELETE_PRODUCT,
  payload
})

export const SAVE_SINGLE_POST_WITH_OWNER_TYPE =
  'product/SAVE_SINGLE_POST_WITH_OWNER_TYPE'
export const saveSinglePostWithOwnerDataAction = (payload) => ({
  type: SAVE_SINGLE_POST_WITH_OWNER_TYPE,
  payload
})
