import axios from 'axios'

export const REGISTER_COMPANY = ({ data, logo, token }) => {
  const {
    name,
    bio,
    email,
    password,
    phone,
    viber,
    whatsapp,
    contactMail,
    city,
    street,
    latitude,
    longitude,
    description
  } = data

  const formData = new FormData()
  formData.append('logo', logo)
  formData.append('name', name)
  formData.append('bio', bio)
  formData.append('login[email]', email)
  formData.append('login[password]', password)
  formData.append('contact[phone]', phone)
  formData.append('contact[viber]', viber)
  formData.append('contact[whatsapp]', whatsapp)
  formData.append('contact[contactMail]', contactMail)
  formData.append('address[city]', city)
  formData.append('address[street]', street)
  formData.append('address[latitude]', latitude)
  formData.append('address[longitude]', longitude)
  formData.append('address[description]', description)

  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/users/register`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    }
  )
}
