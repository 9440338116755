import axios from 'axios'

export const DELETE_POST = async ({ productIdToDelete, token }) => {
  const deletePost = await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/api/products/${productIdToDelete}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return deletePost
}
